import map from "lodash/map"
import groupBy from "lodash/groupBy"

export const state = () => ({
    loading: false,
    error: {},
    data: [],
    matchIds: [],
    isEmpty: false,
    isFavAdded: false
})

export const mutations = {
    set_matchIds(state, data) {
        this.commit("socketFavorite/SET_IDS", data)
        if (this.$router.currentRoute.name === "favorites") {
            state.matchIds = data
            if (data.length > 0) {
                this.commit("socketRate/SET_IDS", data)
                this.commit("socketBookmaker/SET_IDS", data)
                // this.commit('socketMain/SET_IDS', data)
            }
            this.commit("placeBet/set_matchIds", data)
        }
    },
    set_data(state, data) {
        state.data = data
    },
    set_error(state, data) {
        state.error = data
    },
    set_isEmpty(state, data) {
        state.isEmpty = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_isFavAdded(state, data) {
        state.isFavAdded = data
    }
}

export const actions = {
    async toggle({ commit }, { marketId, status }) {
        try {
            await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "FavouriteMatch/saveFavourites",
                data: {
                    _accessToken: this.$auth.getToken("customLocal"),
                    marketId,
                    status
                }
            })
            if (this.$auth.loggedIn) {
                this.dispatch("favorites/get")
                if (status) {
                    commit("set_isFavAdded", status)
                }
            }
        } catch (error) {
            commit("set_error", error)
        }
    },
    async get({ commit }) {
        try {
            let user
            if (this.$auth.loggedIn) {
                user = this.$auth.user._id
            }
            commit("set_data", [])
            commit("set_loading", true)
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                url: "FavouriteMatch/getFavouritesMarket",
                data: {
                    user,
                    _accessToken: this.$auth.getToken("customLocal")
                }
            })
            if (data.data && data.data.length > 0) {
                commit("set_isEmpty", false)
            } else {
                commit("set_isEmpty", true)
            }
            if (data.data) {
                const matchIds = map(data.data, (match) => match.betfairId)
                commit("set_matchIds", matchIds)
                const grouped = groupBy(data.data, "eventType.name")
                const mapped = map(grouped, (val, key) => {
                    return { tab: key, fixtures: val.filter((v, i) => i <= 1) } // limiting to show only 2
                })
                commit("set_data", mapped)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async toggleShow({ commit }, status) {
        await commit("set_isFavAdded", status)
    }
}
