import Vue from "vue"
import _ from "lodash"

Vue.filter("type", function (input) {
    if (input === "LAY") {
        return "KHAI"
    } else if (input === "BACK") {
        return "LAGAI"
    } else if (input === "L") {
        return "K"
    } else {
        return "L"
    }
})

Vue.filter("currencyConverter", function (input, multiplyer) {
    if (!multiplyer) {
        return input
    }
    if (!isNaN(input)) {
        const currency = Math.floor(input * multiplyer)
        if (currency < 10000000) {
            return currency
        } else {
            return "1cr+"
        }
    } else {
        return 0
    }
})
Vue.filter("highCurrencyConverter2", function (input) {
    if (!isNaN(input)) {
        const currency = Math.ceil(input)
        if (String(currency).length <= 3) {
            return input
        } else if (
            String(currency).length === 4 ||
            String(currency).length === 5
        ) {
            return (input / 1000).toFixed(2) + "K"
        } else if (
            String(currency).length === 6 ||
            String(currency).length === 7
        ) {
            return (input / 100000).toFixed(2) + "L"
        } else if (
            String(currency).length === 8 ||
            String(currency).length === 9
        ) {
            return (input / 10000000).toFixed(2) + "Cr"
        } else if (
            String(currency).length === 10 ||
            String(currency).length === 11
        ) {
            return (input / 1000000000).toFixed(2) + "B"
        }
    } else {
        return 0
    }
})

Vue.filter("highCurrencyConverter", function (input) {
    if (!isNaN(input)) {
        if (String(input).length <= 3) {
            return input
        } else if (String(input).length === 4 || String(input).length === 5) {
            return Math.floor(input / 1000) + "K"
        } else if (String(input).length === 6 || String(input).length === 7) {
            return Math.floor(input / 100000) + "L"
        } else if (String(input).length === 8 || String(input).length === 9) {
            return Math.floor(input / 10000000) + "Cr"
        } else if (String(input).length === 10 || String(input).length === 11) {
            return Math.floor(input / 1000000000) + "B"
        }
    } else {
        return 0
    }
})

Vue.filter("currency", function (value, number) {
    if (!value) {
        return ""
    }
    const val = (value / 1).toFixed(number).replace(",", ".")
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
})
Vue.filter("safeUrl", function ($sce) {
    return function (val) {
        return $sce.trustAsResourceUrl(val)
    }
})

Vue.mixin({
    methods: {
        googleCalendarLink: (title = "", matchLink = "", startTime) => {
            startTime = startTime
                ? startTime.toString().replace(/-|:|\.\d+/g, "")
                : null
            let url = "https://www.google.com/calendar/event?action=TEMPLATE"
            url += "&text=" + encodeURIComponent(title)
            url +=
                "&details=" +
                encodeURIComponent(
                    process.env.THEME_SHORT_TITLE + " Game Reminder."
                )
            url += "&location=" + encodeURIComponent(matchLink)
            url += "&dates=" + encodeURIComponent(startTime + "/" + startTime)
            url += "&sprop=website:" + encodeURIComponent(matchLink)
            url +=
                "&sprop=name:" +
                encodeURIComponent(process.env.THEME_SHORT_TITLE)

            return url
        }
    }
})

Vue.filter("numberSymbol", function (num) {
    if (!isNaN(num)) {
        // if (num >= 10000000) {
        //   return (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr'
        // } else if (num >= 100000) {
        //   return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L'
        // } else if (num >= 1000) {
        //   return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
        // }
        return num
    }
})

Vue.filter(
    "currencyFormatInRupees",
    function (number, maximumFractionDigits = 0, locale = "in") {
        const localeList = { in: "en-IN", us: "en-US" }
        const selectedLocale = localeList[locale]
            ? localeList[locale]
            : localeList.in

        if (!isNaN(number) && number) {
            const options = {
                style: "currency",
                currency: "INR",
                maximumFractionDigits,
                minimumFractionDigits: maximumFractionDigits
            }

            const formattedNumber = new Intl.NumberFormat(
                selectedLocale,
                options
            ).format(number)
            return formattedNumber
        } else if (number === "1cr+") {
            return "1cr +"
        } else {
            return "0"
        }
    }
)

Vue.filter(
    "currencyFormat",
    function (number, maximumFractionDigits = 0, locale = "in") {
        number = parseFloat(number)
        const localeList = { in: "en-IN", us: "en-US" }
        let twoFractionObject = {}
        if (maximumFractionDigits === 2) {
            twoFractionObject = {
                style: "currency",
                currency: "INR"
            }
        }
        const selectedLocale = localeList[locale]
            ? localeList[locale]
            : localeList.in
        if (!isNaN(number) && number) {
            const finalFinal = number.toLocaleString(selectedLocale, {
                maximumFractionDigits,
                ...twoFractionObject
            })
            let final
            if (maximumFractionDigits === 2) {
                final = finalFinal.replace("₹", "").replace(".00", "")
            } else if (maximumFractionDigits === 3) {
                final = finalFinal.replace("₹", "").replace(".000", "")
            } else {
                final = finalFinal.replace("₹", "")
            }
            return final
        } else if (number === "1cr+") {
            return "1cr +"
        } else {
            return 0
        }
    }
)

Vue.filter(
    "fancyOdds",
    function (input, market, event, marketId, placeBetCalcType, betType) {
        input = parseFloat(input)
        if (betType === "fancy") {
            input = (input - 1) * 100
        }
        if (marketId && marketId?.includes("bookmaker")) {
            if (marketId && marketId?.includes("-")) {
                return input
            } else if (placeBetCalcType === 0) {
                input = (input / 100 + 1).toFixed(3)
            } else if (placeBetCalcType === 2) {
                input = input.toFixed(3)
            } else {
                input = input.toFixed(3)
            }
        }
        return input
    }
)

Vue.filter("arthemisOdds", function (input, betType) {
    if (betType === "fancy") {
        input = ((input - 1) * 100).toFixed()
    } else {
        input = ((input - 1) * 100).toFixed()
    }
    return input
})

Vue.filter("toFloor", function (input) {
    if (input === 0) {
        return 0
    } else if (input === "" || input === undefined) {
        return ""
    } else {
        return Math.floor(input)
    }
})

Vue.filter("makeSymbol", function (string) {
    let likestring = ""
    if (string !== "") {
        if (string === "INR") {
            likestring = string.replace(/INR/g, "BDT")
        } else {
            likestring = string.replace(/₹/g, "৳")
        }
    }
    return likestring
})

Vue.filter("ceil", function (input) {
    if (input === 0) {
        return 0
    } else if (input === "" || input === undefined) {
        return ""
    } else {
        return Math.ceil(input)
    }
})

Vue.filter("teamName", function (value, value2) {
    value2 = value2 ? value2.replace(/  +/g, " ") : ""
    value = value ? value.replace(/  +/g, " ") : ""
    let teamName = ""
    const teamArray = []
    if (value && value === "The Draw") {
        return value.toUpperCase()
    } else if (value && value2) {
        const eventSplitData = value2.split("v")
        eventSplitData.forEach((singleData) => {
            const teamSplitData = singleData.trim().split(" ")
            if (
                teamSplitData &&
                teamSplitData.length &&
                teamSplitData.length > 1
            ) {
                let newData = ""
                teamSplitData.forEach((singleVal, index) => {
                    newData = _.trim(newData + singleVal[0].toUpperCase())
                })
                teamArray.push(newData.toUpperCase())
            } else {
                teamArray.push(singleData.trim().substring(0, 3).toUpperCase())
            }
        })
        if (
            teamArray &&
            teamArray.length &&
            teamArray[0] &&
            teamArray[1] &&
            teamArray[0] === teamArray[1]
        ) {
            const nameSplitData = value.trim().split(" ")

            if (
                nameSplitData &&
                nameSplitData.length &&
                nameSplitData.length > 1
            ) {
                // let newData = ''

                nameSplitData.forEach((singleData, index) => {
                    // teamName = teamName + singleData[0].trim().toUpperCase()
                    if (!index) {
                        teamName = teamName + singleData.trim().substring(0, 3)
                    } else if (
                        singleData &&
                        singleData.length === 3 &&
                        singleData[0] === "U"
                    ) {
                        teamName = teamName + " " + singleData
                    } else {
                        teamName = teamName + " " + singleData[0]
                    }
                })
            } else {
                teamName = nameSplitData[0].trim().substring(0, 3).toUpperCase()
            }
            return teamName.toUpperCase()
        } else {
            const nameSplitData = value.split(" ")
            if (
                nameSplitData &&
                nameSplitData.length &&
                nameSplitData.length > 1
            ) {
                nameSplitData.forEach((singleData, index) => {
                    let getData = ""
                    getData = _.trim(teamName + singleData[0])
                    teamName = getData.toUpperCase()
                })
            } else {
                teamName = nameSplitData[0].trim().substring(0, 3).toUpperCase()
            }
            return teamName
        }
    }
    return teamName
})
Vue.filter("eventName", function (value) {
    let eventName = ""
    const teamArray = []
    if (value) {
        const eventSplitData = value.split("v")
        eventSplitData.forEach((singleData) => {
            const teamSplitData = singleData.trim().split(" ")
            if (
                teamSplitData &&
                teamSplitData.length &&
                teamSplitData.length > 1
            ) {
                let getData = ""
                let newData = ""
                teamSplitData.forEach((singleVal, index) => {
                    getData = _.trim(newData + singleVal[0])
                    newData = getData.toUpperCase()
                })
                teamArray.push(newData.toUpperCase())
            } else {
                teamArray.push(singleData.trim().substring(0, 3).toUpperCase())
            }
        })
        if (
            teamArray &&
            teamArray.length &&
            teamArray[0] &&
            teamArray[1] &&
            teamArray[0] === teamArray[1]
        ) {
            eventSplitData.forEach((singleData) => {
                const teamSplitData = singleData.trim().split(" ")
                if (
                    teamSplitData &&
                    teamSplitData.length &&
                    teamSplitData.length > 1
                ) {
                    let newData = ""
                    teamSplitData.forEach((singleVal, index) => {
                        if (!index) {
                            newData = newData + singleVal.trim().substring(0, 3)
                        } else if (
                            singleVal &&
                            singleVal.length === 3 &&
                            singleVal[0] === "U"
                        ) {
                            newData = newData + " " + singleVal
                        } else {
                            newData = newData + " " + singleVal[0]
                        }
                    })
                    teamArray.push(newData.toUpperCase())
                } else {
                    teamArray.push(
                        singleData.trim().substring(0, 3).toUpperCase()
                    )
                }
            })
            eventName =
                teamArray && teamArray.length
                    ? `${teamArray[2]} vs ${teamArray[3]}`
                    : eventName
            return eventName
        } else {
            eventName =
                teamArray && teamArray.length
                    ? `${teamArray[0]} vs ${teamArray[1]}`
                    : eventName
        }

        return eventName
    }
    return eventName
})

Vue.filter("playerName", function (value) {
    let name = ""
    if (value) {
        const nameSplitData = value.split(" ")
        if (nameSplitData && nameSplitData.length > 1) {
            nameSplitData.forEach((singleName, index) => {
                if (index) {
                    name = name + singleName
                } else {
                    const val = singleName.trim()
                    name = val[0].toUpperCase() + " " + name
                }
            })
        } else {
            name = value
        }
    }
    return name
})

Vue.filter("optimizeImg", function (imgUrl, width) {
    const newWidth = window.innerWidth < 767 ? width / 2 : width
    const actualImgUrl = imgUrl?.split("/")
    const imgixQuery = `?auto=compress&lossless=false&fm=webp&q=80&fit=crop&w=${newWidth}`
    let finalUrl = ""
    if (
        actualImgUrl &&
        actualImgUrl.length === 5 &&
        actualImgUrl[3] &&
        actualImgUrl[4]
    ) {
        finalUrl =
            process.env.IMGIX_IMAGE_DOMAIN_SW + "/" + actualImgUrl[3] + "/"
        finalUrl = finalUrl + actualImgUrl[4]
    } else if (actualImgUrl && actualImgUrl.length > 0 && actualImgUrl[3]) {
        const lastUrlArray = actualImgUrl[3].split("?")
        if (lastUrlArray && lastUrlArray.length > 0) {
            finalUrl = process.env.IMGIX_IMAGE_DOMAIN_FP + "/" + lastUrlArray[0]
        } else {
            finalUrl = actualImgUrl[3]
        }
    } else {
        finalUrl = process.env.IMGIX_IMAGE_DOMAIN_FP + "/" + imgUrl
    }
    finalUrl = "https://" + finalUrl + imgixQuery
    return finalUrl
})

// Vue.filter("optimizedImage", function (imgUrl, width) {
//     const newWidth = window.innerWidth < 767 ? width / 2 : width
//     const actualImgUrl = imgUrl?.split("/")
//     const imgixQuery = `?auto=compress&lossless=false&fm=webp&q=80&fit=crop&w=${newWidth}`
//     let finalUrl = ""
//     if (actualImgUrl && actualImgUrl.length > 0 && actualImgUrl[3]) {
//         const lastUrlArray = actualImgUrl[3].split("?")
//         if (lastUrlArray && lastUrlArray.length > 0) {
//             finalUrl = process.env.IMGIX_IMAGE_DOMAIN_FP + "/" + lastUrlArray[0]
//         } else {
//             finalUrl = actualImgUrl[3]
//         }
//     } else {
//         finalUrl = process.env.IMGIX_IMAGE_DOMAIN_FP + "/" + imgUrl
//     }
//     finalUrl = "https://" + finalUrl + imgixQuery
//     return finalUrl
// })

Vue.filter("newOptimizedImage", function (imgUrl, width) {
    const newWidth = window.innerWidth < 767 ? width / 2 : width
    const actualImgUrl = imgUrl?.split("/")
    const imgixQuery = `?auto=compress&lossless=false&fm=webp&q=80&fit=crop&w=${newWidth}`
    let finalUrl = ""
    if (actualImgUrl && actualImgUrl.length > 0 && actualImgUrl[3]) {
        const lastUrlArray = actualImgUrl[3].split("?")
        if (lastUrlArray && lastUrlArray.length > 0) {
            finalUrl = process.env.IMGIX_IMAGE_DOMAIN + "/" + lastUrlArray[0]
        } else {
            finalUrl = actualImgUrl[3]
        }
    } else {
        finalUrl = process.env.IMGIX_IMAGE_DOMAIN + "/" + imgUrl
    }
    finalUrl = "https://" + finalUrl + imgixQuery
    return finalUrl
})

Vue.mixin({
    methods: {
        ceilMethod: (input) => {
            if (input === 0) {
                return 0
            } else if (input === "" || input === undefined) {
                return ""
            } else {
                return Math.ceil(input)
            }
        }
    }
})
