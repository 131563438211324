import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e9b98470 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _3e331464 = () => interopDefault(import('../pages/affiliate-partner.vue' /* webpackChunkName: "pages/affiliate-partner" */))
const _64a36327 = () => interopDefault(import('../pages/aml-policies.vue' /* webpackChunkName: "pages/aml-policies" */))
const _025a6428 = () => interopDefault(import('../pages/bets.vue' /* webpackChunkName: "pages/bets" */))
const _570d04f6 = () => interopDefault(import('../pages/blockuserip.vue' /* webpackChunkName: "pages/blockuserip" */))
const _0bbea366 = () => interopDefault(import('../pages/blog-old.vue' /* webpackChunkName: "pages/blog-old" */))
const _4273aa0d = () => interopDefault(import('../pages/book-register.vue' /* webpackChunkName: "pages/book-register" */))
const _2ed52922 = () => interopDefault(import('../pages/casino/index.vue' /* webpackChunkName: "pages/casino/index" */))
const _9b4ab008 = () => interopDefault(import('../pages/casino-old/index.vue' /* webpackChunkName: "pages/casino-old/index" */))
const _898fbfec = () => interopDefault(import('../pages/casino-slots/index.vue' /* webpackChunkName: "pages/casino-slots/index" */))
const _7bf2f866 = () => interopDefault(import('../pages/change-mobileno.vue' /* webpackChunkName: "pages/change-mobileno" */))
const _0012cdc4 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _64894ef4 = () => interopDefault(import('../pages/check-in.vue' /* webpackChunkName: "pages/check-in" */))
const _521bf555 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _1952df18 = () => interopDefault(import('../pages/dglp/index.vue' /* webpackChunkName: "pages/dglp/index" */))
const _af9f7dce = () => interopDefault(import('../pages/enroll-now.vue' /* webpackChunkName: "pages/enroll-now" */))
const _92c60514 = () => interopDefault(import('../pages/fantasy-pro-rules.vue' /* webpackChunkName: "pages/fantasy-pro-rules" */))
const _54745658 = () => interopDefault(import('../pages/fantasybook/index.vue' /* webpackChunkName: "pages/fantasybook/index" */))
const _74bfeaf2 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _2bf86b1c = () => interopDefault(import('../pages/fast-signup/index.vue' /* webpackChunkName: "pages/fast-signup/index" */))
const _8c6d3346 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _772588e2 = () => interopDefault(import('../pages/finsa/index.vue' /* webpackChunkName: "pages/finsa/index" */))
const _0417bc8b = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _4918eed6 = () => interopDefault(import('../pages/gift-card/index.vue' /* webpackChunkName: "pages/gift-card/index" */))
const _81fbb47a = () => interopDefault(import('../pages/inplay.vue' /* webpackChunkName: "pages/inplay" */))
const _5ee13f65 = () => interopDefault(import('../pages/line-market-rules.vue' /* webpackChunkName: "pages/line-market-rules" */))
const _08ed19a2 = () => interopDefault(import('../pages/live-cards/index.vue' /* webpackChunkName: "pages/live-cards/index" */))
const _db8fe302 = () => interopDefault(import('../pages/live-casino/index.vue' /* webpackChunkName: "pages/live-casino/index" */))
const _7bccbde6 = () => interopDefault(import('../pages/live-games/index.vue' /* webpackChunkName: "pages/live-games/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _555cd768 = () => interopDefault(import('../pages/loyalty.vue' /* webpackChunkName: "pages/loyalty" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _09484100 = () => interopDefault(import('../pages/my-markets.vue' /* webpackChunkName: "pages/my-markets" */))
const _04b10682 = () => interopDefault(import('../pages/new-pending-bonus.vue' /* webpackChunkName: "pages/new-pending-bonus" */))
const _7e8ea1d8 = () => interopDefault(import('../pages/new-pending-sportbonus.vue' /* webpackChunkName: "pages/new-pending-sportbonus" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _5172b96e = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _8e94d904 = () => interopDefault(import('../pages/online-betting-app1.vue' /* webpackChunkName: "pages/online-betting-app1" */))
const _1ec66c6f = () => interopDefault(import('../pages/pending-bonus.vue' /* webpackChunkName: "pages/pending-bonus" */))
const _f83c35b0 = () => interopDefault(import('../pages/playnow.vue' /* webpackChunkName: "pages/playnow" */))
const _367ad087 = () => interopDefault(import('../pages/premium-bookmaker-fancy.vue' /* webpackChunkName: "pages/premium-bookmaker-fancy" */))
const _3fb959aa = () => interopDefault(import('../pages/premium-cricket-rules.vue' /* webpackChunkName: "pages/premium-cricket-rules" */))
const _0f0c8f0b = () => interopDefault(import('../pages/premium-sportsbook/index.vue' /* webpackChunkName: "pages/premium-sportsbook/index" */))
const _4a159b1c = () => interopDefault(import('../pages/premium-sportsbook-rules.vue' /* webpackChunkName: "pages/premium-sportsbook-rules" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _d79f07d6 = () => interopDefault(import('../pages/profit-loss/index.vue' /* webpackChunkName: "pages/profit-loss/index" */))
const _6bb801a8 = () => interopDefault(import('../pages/profit-loss-new/index.vue' /* webpackChunkName: "pages/profit-loss-new/index" */))
const _3eb5f8cc = () => interopDefault(import('../pages/promotions.vue' /* webpackChunkName: "pages/promotions" */))
const _1b0ef35a = () => interopDefault(import('../pages/quick-checkin.vue' /* webpackChunkName: "pages/quick-checkin" */))
const _03b2a470 = () => interopDefault(import('../pages/quick-enroll.vue' /* webpackChunkName: "pages/quick-enroll" */))
const _cda54fe2 = () => interopDefault(import('../pages/quick-joinus1.vue' /* webpackChunkName: "pages/quick-joinus1" */))
const _cd8920e0 = () => interopDefault(import('../pages/quick-joinus2.vue' /* webpackChunkName: "pages/quick-joinus2" */))
const _81bbcb8c = () => interopDefault(import('../pages/quick-reg.vue' /* webpackChunkName: "pages/quick-reg" */))
const _2538d668 = () => interopDefault(import('../pages/quick-reg2.vue' /* webpackChunkName: "pages/quick-reg2" */))
const _372610e5 = () => interopDefault(import('../pages/quick-signup/index.vue' /* webpackChunkName: "pages/quick-signup/index" */))
const _734206f2 = () => interopDefault(import('../pages/quick-signup-2.vue' /* webpackChunkName: "pages/quick-signup-2" */))
const _2450db82 = () => interopDefault(import('../pages/quick-signup1.vue' /* webpackChunkName: "pages/quick-signup1" */))
const _2434ac80 = () => interopDefault(import('../pages/quick-signup2.vue' /* webpackChunkName: "pages/quick-signup2" */))
const _24187d7e = () => interopDefault(import('../pages/quick-signup3.vue' /* webpackChunkName: "pages/quick-signup3" */))
const _23fc4e7c = () => interopDefault(import('../pages/quick-signup4.vue' /* webpackChunkName: "pages/quick-signup4" */))
const _bd420d8a = () => interopDefault(import('../pages/racing/index.vue' /* webpackChunkName: "pages/racing/index" */))
const _41897507 = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _25c5b55e = () => interopDefault(import('../pages/region-block.vue' /* webpackChunkName: "pages/region-block" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _1bb7fb23 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _5b5f129f = () => interopDefault(import('../pages/registration2.vue' /* webpackChunkName: "pages/registration2" */))
const _5b6d2a20 = () => interopDefault(import('../pages/registration3.vue' /* webpackChunkName: "pages/registration3" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _eca09774 = () => interopDefault(import('../pages/responsible-gambling.vue' /* webpackChunkName: "pages/responsible-gambling" */))
const _7641eb8e = () => interopDefault(import('../pages/rules-regulations.vue' /* webpackChunkName: "pages/rules-regulations" */))
const _137800ab = () => interopDefault(import('../pages/rummy-poker/index.vue' /* webpackChunkName: "pages/rummy-poker/index" */))
const _3543fae6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _07f8a58a = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _cb830956 = () => interopDefault(import('../pages/sportsbook/index.vue' /* webpackChunkName: "pages/sportsbook/index" */))
const _0980ecae = () => interopDefault(import('../pages/store-profit-loss/index.vue' /* webpackChunkName: "pages/store-profit-loss/index" */))
const _3f53a6c4 = () => interopDefault(import('../pages/survey.vue' /* webpackChunkName: "pages/survey" */))
const _6fe391dc = () => interopDefault(import('../pages/termsandconditions.vue' /* webpackChunkName: "pages/termsandconditions" */))
const _34199a5f = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _2bb45229 = () => interopDefault(import('../pages/time-settings.vue' /* webpackChunkName: "pages/time-settings" */))
const _3573edbf = () => interopDefault(import('../pages/transactions.vue' /* webpackChunkName: "pages/transactions" */))
const _31a039e8 = () => interopDefault(import('../pages/transfer-statements.vue' /* webpackChunkName: "pages/transfer-statements" */))
const _0de345df = () => interopDefault(import('../pages/transfer-statements-new.vue' /* webpackChunkName: "pages/transfer-statements-new" */))
const _53e6cddb = () => interopDefault(import('../pages/tutorials.vue' /* webpackChunkName: "pages/tutorials" */))
const _722749b1 = () => interopDefault(import('../pages/user-book.vue' /* webpackChunkName: "pages/user-book" */))
const _4fe5df81 = () => interopDefault(import('../pages/user-registration.vue' /* webpackChunkName: "pages/user-registration" */))
const _17eff8bc = () => interopDefault(import('../pages/wac/index.vue' /* webpackChunkName: "pages/wac/index" */))
const _0febbc26 = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "pages/wallet/index" */))
const _43ecfba0 = () => interopDefault(import('../pages/casino-old/live-casino.vue' /* webpackChunkName: "pages/casino-old/live-casino" */))
const _3008b5c9 = () => interopDefault(import('../pages/casino/casino-list/index.vue' /* webpackChunkName: "pages/casino/casino-list/index" */))
const _c8a2b8de = () => interopDefault(import('../pages/casino/games.vue' /* webpackChunkName: "pages/casino/games" */))
const _f42b7b6e = () => interopDefault(import('../pages/casino/live-casino/index.vue' /* webpackChunkName: "pages/casino/live-casino/index" */))
const _355705e1 = () => interopDefault(import('../pages/fantasybook/edit-stake.vue' /* webpackChunkName: "pages/fantasybook/edit-stake" */))
const _006ade86 = () => interopDefault(import('../pages/live-casino-old/live-casino.vue' /* webpackChunkName: "pages/live-casino-old/live-casino" */))
const _35712ef2 = () => interopDefault(import('../pages/markets/live-cards/index.vue' /* webpackChunkName: "pages/markets/live-cards/index" */))
const _f8071ea6 = () => interopDefault(import('../pages/markets/sportsbook/index.vue' /* webpackChunkName: "pages/markets/sportsbook/index" */))
const _b8c97a2a = () => interopDefault(import('../pages/profit-loss-new/detail.vue' /* webpackChunkName: "pages/profit-loss-new/detail" */))
const _eb9bd3c4 = () => interopDefault(import('../pages/profit-loss/detail.vue' /* webpackChunkName: "pages/profit-loss/detail" */))
const _149478b2 = () => interopDefault(import('../pages/quick-signup/thankyou.vue' /* webpackChunkName: "pages/quick-signup/thankyou" */))
const _ba57361c = () => interopDefault(import('../pages/wallet/deposit.vue' /* webpackChunkName: "pages/wallet/deposit" */))
const _b48e6406 = () => interopDefault(import('../pages/wallet/instant-withdrawal.vue' /* webpackChunkName: "pages/wallet/instant-withdrawal" */))
const _5635e891 = () => interopDefault(import('../pages/wallet/withdrawal.vue' /* webpackChunkName: "pages/wallet/withdrawal" */))
const _5b437629 = () => interopDefault(import('../pages/casino/casino-list/index-copy.vue' /* webpackChunkName: "pages/casino/casino-list/index-copy" */))
const _438e55ff = () => interopDefault(import('../pages/markets/live-cards/32-card-casino.vue' /* webpackChunkName: "pages/markets/live-cards/32-card-casino" */))
const _23a96f62 = () => interopDefault(import('../pages/markets/live-cards/7up7down.vue' /* webpackChunkName: "pages/markets/live-cards/7up7down" */))
const _74956600 = () => interopDefault(import('../pages/markets/live-cards/amar-akbar-anthony.vue' /* webpackChunkName: "pages/markets/live-cards/amar-akbar-anthony" */))
const _39e0e0aa = () => interopDefault(import('../pages/markets/live-cards/andar-bahar.vue' /* webpackChunkName: "pages/markets/live-cards/andar-bahar" */))
const _912ec040 = () => interopDefault(import('../pages/markets/live-cards/dragon-tiger.vue' /* webpackChunkName: "pages/markets/live-cards/dragon-tiger" */))
const _29ab9ab8 = () => interopDefault(import('../pages/markets/live-cards/live-teenpatti.vue' /* webpackChunkName: "pages/markets/live-cards/live-teenpatti" */))
const _66029fa4 = () => interopDefault(import('../pages/markets/live-cards/poker.vue' /* webpackChunkName: "pages/markets/live-cards/poker" */))
const _76cf5f98 = () => interopDefault(import('../pages/markets/live-cards/t20-teenpatti.vue' /* webpackChunkName: "pages/markets/live-cards/t20-teenpatti" */))
const _3b8f2816 = () => interopDefault(import('../pages/markets/live-cards/worli-matka.vue' /* webpackChunkName: "pages/markets/live-cards/worli-matka" */))
const _aaefb85c = () => interopDefault(import('../pages/wallet/product/deposit.vue' /* webpackChunkName: "pages/wallet/product/deposit" */))
const _b731849e = () => interopDefault(import('../pages/wallet/product/withdrawal.vue' /* webpackChunkName: "pages/wallet/product/withdrawal" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1ad3e0af = () => interopDefault(import('../pages/markets/live-cards/_id.vue' /* webpackChunkName: "pages/markets/live-cards/_id" */))
const _08b0b762 = () => interopDefault(import('../pages/markets/live-casino/_id.vue' /* webpackChunkName: "pages/markets/live-casino/_id" */))
const _714e8b56 = () => interopDefault(import('../pages/markets/sportsbook/_id.vue' /* webpackChunkName: "pages/markets/sportsbook/_id" */))
const _6fadcd28 = () => interopDefault(import('../pages/fantasybook/inviteLink/_type/_joinContentCode/index.vue' /* webpackChunkName: "pages/fantasybook/inviteLink/_type/_joinContentCode/index" */))
const _d9ee44d6 = () => interopDefault(import('../pages/fantasybook/match/_game/_matchType/index.vue' /* webpackChunkName: "pages/fantasybook/match/_game/_matchType/index" */))
const _252a9fe8 = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/index" */))
const _1a85c708 = () => interopDefault(import('../pages/fantasybook/match-pools/_betfairId/_game/_gameType/index.vue' /* webpackChunkName: "pages/fantasybook/match-pools/_betfairId/_game/_gameType/index" */))
const _5014e4f7 = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index" */))
const _dd32ed46 = () => interopDefault(import('../pages/fantasybook/match/_game/_matchType/_betfairId/_gameType/index.vue' /* webpackChunkName: "pages/fantasybook/match/_game/_matchType/_betfairId/_gameType/index" */))
const _7b6efaca = () => interopDefault(import('../pages/fantasybook/choose-winner/_betfairId/_gameType/_contestName/_contestSize/_entryFees/index.vue' /* webpackChunkName: "pages/fantasybook/choose-winner/_betfairId/_gameType/_contestName/_contestSize/_entryFees/index" */))
const _54575020 = () => interopDefault(import('../pages/fantasybook/match-inner/_parentCategory/_betfairId/_game/_gameType/_contestId/index.vue' /* webpackChunkName: "pages/fantasybook/match-inner/_parentCategory/_betfairId/_game/_gameType/_contestId/index" */))
const _e33150c4 = () => interopDefault(import('../pages/fantasybook/users-leaderboard/_userId/_betfairId/_game/_status/_contestId/_playerId/index.vue' /* webpackChunkName: "pages/fantasybook/users-leaderboard/_userId/_betfairId/_game/_status/_contestId/_playerId/index" */))
const _5f079576 = () => interopDefault(import('../pages/fantasybook/users-leaderboard/_parentCategory/_userId/_betfairId/_game/_status/_contestId/_playerId/index.vue' /* webpackChunkName: "pages/fantasybook/users-leaderboard/_parentCategory/_userId/_betfairId/_game/_status/_contestId/_playerId/index" */))
const _ab879afe = () => interopDefault(import('../pages/affiliate/_id/index.vue' /* webpackChunkName: "pages/affiliate/_id/index" */))
const _b9ab0d38 = () => interopDefault(import('../pages/casino-old/_id.vue' /* webpackChunkName: "pages/casino-old/_id" */))
const _3cfac61c = () => interopDefault(import('../pages/casino-slots/_id.vue' /* webpackChunkName: "pages/casino-slots/_id" */))
const _23e87557 = () => interopDefault(import('../pages/live-cards/_id.vue' /* webpackChunkName: "pages/live-cards/_id" */))
const _86ddeb30 = () => interopDefault(import('../pages/markets/_id.vue' /* webpackChunkName: "pages/markets/_id" */))
const _6270973a = () => interopDefault(import('../pages/sportsbook/_category/index.vue' /* webpackChunkName: "pages/sportsbook/_category/index" */))
const _13a6868a = () => interopDefault(import('../pages/wac/_id.vue' /* webpackChunkName: "pages/wac/_id" */))
const _877bed9c = () => interopDefault(import('../pages/casino-old/_category/_id.vue' /* webpackChunkName: "pages/casino-old/_category/_id" */))
const _cf998f0c = () => interopDefault(import('../pages/fantasybook/_betfairId/_gameType/index.vue' /* webpackChunkName: "pages/fantasybook/_betfairId/_gameType/index" */))
const _11e269e2 = () => interopDefault(import('../pages/live-casino-old/_category/_id.vue' /* webpackChunkName: "pages/live-casino-old/_category/_id" */))
const _c5bd6084 = () => interopDefault(import('../pages/live-casino/_system/_pageNo/index.vue' /* webpackChunkName: "pages/live-casino/_system/_pageNo/index" */))
const _0f8bbb54 = () => interopDefault(import('../pages/referalLink/_id/_name/index.vue' /* webpackChunkName: "pages/referalLink/_id/_name/index" */))
const _dd1256be = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/index.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/index" */))
const _3d5bdc13 = () => interopDefault(import('../pages/promote/_name/_decryptPw/_countryCode/index.vue' /* webpackChunkName: "pages/promote/_name/_decryptPw/_countryCode/index" */))
const _261781ad = () => interopDefault(import('../pages/casino-old/_pn/_lang/_provider/live-casino.vue' /* webpackChunkName: "pages/casino-old/_pn/_lang/_provider/live-casino" */))
const _1f0537ba = () => interopDefault(import('../pages/casino-old/_pn/_lang/_provider/_tableId.vue' /* webpackChunkName: "pages/casino-old/_pn/_lang/_provider/_tableId" */))
const _1a96c6ae = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/_id/_betradar.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/_id/_betradar" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _e9b98470,
    name: "about-us"
  }, {
    path: "/affiliate-partner",
    component: _3e331464,
    name: "affiliate-partner"
  }, {
    path: "/aml-policies",
    component: _64a36327,
    name: "aml-policies"
  }, {
    path: "/bets",
    component: _025a6428,
    name: "bets"
  }, {
    path: "/blockuserip",
    component: _570d04f6,
    name: "blockuserip"
  }, {
    path: "/blog-old",
    component: _0bbea366,
    name: "blog-old"
  }, {
    path: "/book-register",
    component: _4273aa0d,
    name: "book-register"
  }, {
    path: "/casino",
    component: _2ed52922,
    name: "casino"
  }, {
    path: "/casino-old",
    component: _9b4ab008,
    name: "casino-old"
  }, {
    path: "/casino-slots",
    component: _898fbfec,
    name: "casino-slots"
  }, {
    path: "/change-mobileno",
    component: _7bf2f866,
    name: "change-mobileno"
  }, {
    path: "/change-password",
    component: _0012cdc4,
    name: "change-password"
  }, {
    path: "/check-in",
    component: _64894ef4,
    name: "check-in"
  }, {
    path: "/contact-us",
    component: _521bf555,
    name: "contact-us"
  }, {
    path: "/dglp",
    component: _1952df18,
    name: "dglp"
  }, {
    path: "/enroll-now",
    component: _af9f7dce,
    name: "enroll-now"
  }, {
    path: "/fantasy-pro-rules",
    component: _92c60514,
    name: "fantasy-pro-rules"
  }, {
    path: "/fantasybook",
    component: _54745658,
    name: "fantasybook"
  }, {
    path: "/faqs",
    component: _74bfeaf2,
    name: "faqs"
  }, {
    path: "/fast-signup",
    component: _2bf86b1c,
    name: "fast-signup"
  }, {
    path: "/favorites",
    component: _8c6d3346,
    name: "favorites"
  }, {
    path: "/finsa",
    component: _772588e2,
    name: "finsa"
  }, {
    path: "/forgot-password",
    component: _0417bc8b,
    name: "forgot-password"
  }, {
    path: "/gift-card",
    component: _4918eed6,
    name: "gift-card"
  }, {
    path: "/inplay",
    component: _81fbb47a,
    name: "inplay"
  }, {
    path: "/line-market-rules",
    component: _5ee13f65,
    name: "line-market-rules"
  }, {
    path: "/live-cards",
    component: _08ed19a2,
    name: "live-cards"
  }, {
    path: "/live-casino",
    component: _db8fe302,
    name: "live-casino"
  }, {
    path: "/live-games",
    component: _7bccbde6,
    name: "live-games"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/loyalty",
    component: _555cd768,
    name: "loyalty"
  }, {
    path: "/maintenance",
    component: _4e16ad99,
    name: "maintenance"
  }, {
    path: "/my-markets",
    component: _09484100,
    name: "my-markets"
  }, {
    path: "/new-pending-bonus",
    component: _04b10682,
    name: "new-pending-bonus"
  }, {
    path: "/new-pending-sportbonus",
    component: _7e8ea1d8,
    name: "new-pending-sportbonus"
  }, {
    path: "/news",
    component: _3b011b86,
    name: "news"
  }, {
    path: "/notifications",
    component: _5172b96e,
    name: "notifications"
  }, {
    path: "/online-betting-app1",
    component: _8e94d904,
    name: "online-betting-app1"
  }, {
    path: "/pending-bonus",
    component: _1ec66c6f,
    name: "pending-bonus"
  }, {
    path: "/playnow",
    component: _f83c35b0,
    name: "playnow"
  }, {
    path: "/premium-bookmaker-fancy",
    component: _367ad087,
    name: "premium-bookmaker-fancy"
  }, {
    path: "/premium-cricket-rules",
    component: _3fb959aa,
    name: "premium-cricket-rules"
  }, {
    path: "/premium-sportsbook",
    component: _0f0c8f0b,
    name: "premium-sportsbook"
  }, {
    path: "/premium-sportsbook-rules",
    component: _4a159b1c,
    name: "premium-sportsbook-rules"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile"
  }, {
    path: "/profit-loss",
    component: _d79f07d6,
    name: "profit-loss"
  }, {
    path: "/profit-loss-new",
    component: _6bb801a8,
    name: "profit-loss-new"
  }, {
    path: "/promotions",
    component: _3eb5f8cc,
    name: "promotions"
  }, {
    path: "/quick-checkin",
    component: _1b0ef35a,
    name: "quick-checkin"
  }, {
    path: "/quick-enroll",
    component: _03b2a470,
    name: "quick-enroll"
  }, {
    path: "/quick-joinus1",
    component: _cda54fe2,
    name: "quick-joinus1"
  }, {
    path: "/quick-joinus2",
    component: _cd8920e0,
    name: "quick-joinus2"
  }, {
    path: "/quick-reg",
    component: _81bbcb8c,
    name: "quick-reg"
  }, {
    path: "/quick-reg2",
    component: _2538d668,
    name: "quick-reg2"
  }, {
    path: "/quick-signup",
    component: _372610e5,
    name: "quick-signup"
  }, {
    path: "/quick-signup-2",
    component: _734206f2,
    name: "quick-signup-2"
  }, {
    path: "/quick-signup1",
    component: _2450db82,
    name: "quick-signup1"
  }, {
    path: "/quick-signup2",
    component: _2434ac80,
    name: "quick-signup2"
  }, {
    path: "/quick-signup3",
    component: _24187d7e,
    name: "quick-signup3"
  }, {
    path: "/quick-signup4",
    component: _23fc4e7c,
    name: "quick-signup4"
  }, {
    path: "/racing",
    component: _bd420d8a,
    name: "racing"
  }, {
    path: "/referral",
    component: _41897507,
    name: "referral"
  }, {
    path: "/region-block",
    component: _25c5b55e,
    name: "region-block"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/registration",
    component: _1bb7fb23,
    name: "registration"
  }, {
    path: "/registration2",
    component: _5b5f129f,
    name: "registration2"
  }, {
    path: "/registration3",
    component: _5b6d2a20,
    name: "registration3"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password"
  }, {
    path: "/responsible-gambling",
    component: _eca09774,
    name: "responsible-gambling"
  }, {
    path: "/rules-regulations",
    component: _7641eb8e,
    name: "rules-regulations"
  }, {
    path: "/rummy-poker",
    component: _137800ab,
    name: "rummy-poker"
  }, {
    path: "/settings",
    component: _3543fae6,
    name: "settings"
  }, {
    path: "/sitemap",
    component: _07f8a58a,
    name: "sitemap"
  }, {
    path: "/sportsbook",
    component: _cb830956,
    name: "sportsbook"
  }, {
    path: "/store-profit-loss",
    component: _0980ecae,
    name: "store-profit-loss"
  }, {
    path: "/survey",
    component: _3f53a6c4,
    name: "survey"
  }, {
    path: "/termsandconditions",
    component: _6fe391dc,
    name: "termsandconditions"
  }, {
    path: "/thankyou",
    component: _34199a5f,
    name: "thankyou"
  }, {
    path: "/time-settings",
    component: _2bb45229,
    name: "time-settings"
  }, {
    path: "/transactions",
    component: _3573edbf,
    name: "transactions"
  }, {
    path: "/transfer-statements",
    component: _31a039e8,
    name: "transfer-statements"
  }, {
    path: "/transfer-statements-new",
    component: _0de345df,
    name: "transfer-statements-new"
  }, {
    path: "/tutorials",
    component: _53e6cddb,
    name: "tutorials"
  }, {
    path: "/user-book",
    component: _722749b1,
    name: "user-book"
  }, {
    path: "/user-registration",
    component: _4fe5df81,
    name: "user-registration"
  }, {
    path: "/wac",
    component: _17eff8bc,
    name: "wac"
  }, {
    path: "/wallet",
    component: _0febbc26,
    name: "wallet"
  }, {
    path: "/casino-old/live-casino",
    component: _43ecfba0,
    name: "casino-old-live-casino"
  }, {
    path: "/casino/casino-list",
    component: _3008b5c9,
    name: "casino-casino-list"
  }, {
    path: "/casino/games",
    component: _c8a2b8de,
    name: "casino-games"
  }, {
    path: "/casino/live-casino",
    component: _f42b7b6e,
    name: "casino-live-casino"
  }, {
    path: "/fantasybook/edit-stake",
    component: _355705e1,
    name: "fantasybook-edit-stake"
  }, {
    path: "/live-casino-old/live-casino",
    component: _006ade86,
    name: "live-casino-old-live-casino"
  }, {
    path: "/markets/live-cards",
    component: _35712ef2,
    name: "markets-live-cards"
  }, {
    path: "/markets/sportsbook",
    component: _f8071ea6,
    name: "markets-sportsbook"
  }, {
    path: "/profit-loss-new/detail",
    component: _b8c97a2a,
    name: "profit-loss-new-detail"
  }, {
    path: "/profit-loss/detail",
    component: _eb9bd3c4,
    name: "profit-loss-detail"
  }, {
    path: "/quick-signup/thankyou",
    component: _149478b2,
    name: "quick-signup-thankyou"
  }, {
    path: "/wallet/deposit",
    component: _ba57361c,
    name: "wallet-deposit"
  }, {
    path: "/wallet/instant-withdrawal",
    component: _b48e6406,
    name: "wallet-instant-withdrawal"
  }, {
    path: "/wallet/withdrawal",
    component: _5635e891,
    name: "wallet-withdrawal"
  }, {
    path: "/casino/casino-list/index-copy",
    component: _5b437629,
    name: "casino-casino-list-index-copy"
  }, {
    path: "/markets/live-cards/32-card-casino",
    component: _438e55ff,
    name: "markets-live-cards-32-card-casino"
  }, {
    path: "/markets/live-cards/7up7down",
    component: _23a96f62,
    name: "markets-live-cards-7up7down"
  }, {
    path: "/markets/live-cards/amar-akbar-anthony",
    component: _74956600,
    name: "markets-live-cards-amar-akbar-anthony"
  }, {
    path: "/markets/live-cards/andar-bahar",
    component: _39e0e0aa,
    name: "markets-live-cards-andar-bahar"
  }, {
    path: "/markets/live-cards/dragon-tiger",
    component: _912ec040,
    name: "markets-live-cards-dragon-tiger"
  }, {
    path: "/markets/live-cards/live-teenpatti",
    component: _29ab9ab8,
    name: "markets-live-cards-live-teenpatti"
  }, {
    path: "/markets/live-cards/poker",
    component: _66029fa4,
    name: "markets-live-cards-poker"
  }, {
    path: "/markets/live-cards/t20-teenpatti",
    component: _76cf5f98,
    name: "markets-live-cards-t20-teenpatti"
  }, {
    path: "/markets/live-cards/worli-matka",
    component: _3b8f2816,
    name: "markets-live-cards-worli-matka"
  }, {
    path: "/wallet/product/deposit",
    component: _aaefb85c,
    name: "wallet-product-deposit"
  }, {
    path: "/wallet/product/withdrawal",
    component: _b731849e,
    name: "wallet-product-withdrawal"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/markets/live-cards/:id?",
    component: _1ad3e0af,
    name: "markets-live-cards-id"
  }, {
    path: "/markets/live-casino/:id?",
    component: _08b0b762,
    name: "markets-live-casino-id"
  }, {
    path: "/markets/sportsbook/:id",
    component: _714e8b56,
    name: "markets-sportsbook-id"
  }, {
    path: "/fantasybook/inviteLink/:type?/:joinContentCode",
    component: _6fadcd28,
    name: "fantasybook-inviteLink-type-joinContentCode"
  }, {
    path: "/fantasybook/match/:game?/:matchType",
    component: _d9ee44d6,
    name: "fantasybook-match-game-matchType"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType",
    component: _252a9fe8,
    name: "casino-casino-list-provider-gameType-subGameType"
  }, {
    path: "/fantasybook/match-pools/:betfairId?/:game?/:gameType",
    component: _1a85c708,
    name: "fantasybook-match-pools-betfairId-game-gameType"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType?/:id",
    component: _5014e4f7,
    name: "casino-casino-list-provider-gameType-subGameType-id"
  }, {
    path: "/fantasybook/match/:game?/:matchType?/:betfairId/:gameType",
    component: _dd32ed46,
    name: "fantasybook-match-game-matchType-betfairId-gameType"
  }, {
    path: "/fantasybook/choose-winner/:betfairId?/:gameType?/:contestName?/:contestSize?/:entryFees",
    component: _7b6efaca,
    name: "fantasybook-choose-winner-betfairId-gameType-contestName-contestSize-entryFees"
  }, {
    path: "/fantasybook/match-inner/:parentCategory?/:betfairId?/:game?/:gameType?/:contestId",
    component: _54575020,
    name: "fantasybook-match-inner-parentCategory-betfairId-game-gameType-contestId"
  }, {
    path: "/fantasybook/users-leaderboard/:userId?/:betfairId?/:game?/:status?/:contestId?/:playerId",
    component: _e33150c4,
    name: "fantasybook-users-leaderboard-userId-betfairId-game-status-contestId-playerId"
  }, {
    path: "/fantasybook/users-leaderboard/:parentCategory?/:userId?/:betfairId?/:game?/:status?/:contestId?/:playerId",
    component: _5f079576,
    name: "fantasybook-users-leaderboard-parentCategory-userId-betfairId-game-status-contestId-playerId"
  }, {
    path: "/affiliate/:id",
    component: _ab879afe,
    name: "affiliate-id"
  }, {
    path: "/casino-old/:id",
    component: _b9ab0d38,
    name: "casino-old-id"
  }, {
    path: "/casino-slots/:id",
    component: _3cfac61c,
    name: "casino-slots-id"
  }, {
    path: "/live-cards/:id?",
    component: _23e87557,
    name: "live-cards-id"
  }, {
    path: "/markets/:id?",
    component: _86ddeb30,
    name: "markets-id"
  }, {
    path: "/sportsbook/:category",
    component: _6270973a,
    name: "sportsbook-category"
  }, {
    path: "/wac/:id",
    component: _13a6868a,
    name: "wac-id"
  }, {
    path: "/casino-old/:category/:id",
    component: _877bed9c,
    name: "casino-old-category-id"
  }, {
    path: "/fantasybook/:betfairId/:gameType",
    component: _cf998f0c,
    name: "fantasybook-betfairId-gameType"
  }, {
    path: "/live-casino-old/:category?/:id",
    component: _11e269e2,
    name: "live-casino-old-category-id"
  }, {
    path: "/live-casino/:system?/:pageNo",
    component: _c5bd6084,
    name: "live-casino-system-pageNo"
  }, {
    path: "/referalLink/:id?/:name",
    component: _0f8bbb54,
    name: "referalLink-id-name"
  }, {
    path: "/sportsbook/:category/:subCategory",
    component: _dd1256be,
    name: "sportsbook-category-subCategory"
  }, {
    path: "/promote/:name?/:decryptPw?/:countryCode",
    component: _3d5bdc13,
    name: "promote-name-decryptPw-countryCode"
  }, {
    path: "/casino-old/:pn/:lang/:provider?/live-casino",
    component: _261781ad,
    name: "casino-old-pn-lang-provider-live-casino"
  }, {
    path: "/casino-old/:pn/:lang/:provider?/:tableId?",
    component: _1f0537ba,
    name: "casino-old-pn-lang-provider-tableId"
  }, {
    path: "/sportsbook/:category/:subCategory/:id/:betradar?",
    component: _1a96c6ae,
    name: "sportsbook-category-subCategory-id-betradar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
