// import moment from 'moment'
// import nodemailer from 'nodemailer'
import service from "../services/apiService"

export const state = () => ({
    data: {},
    emailHtmlContent: `Greetings,<br />
<br />
We noticed you enquired about our Affiliate program. Here are some of the basic details regarding the program.<br />
<br />
Payout ratio to affiliates will be in accordance with number of players introduced and playing on our portal.<br />
<br />
1 to 50 Members, 10% payout on cummulative loss of players<br />
<br />
51 to 200 Members, 20% payout on cummulative loss of players<br />
<br />
200 + Members, 30% payout on cummulative loss of players.<br />
<br />
<br />
Payouts will be processed by the 5th of the following month. For more details, please contact us on directly via Whatsapp or you can alternatively reply back to this mail.<br />
<br />
Fairplay reserves rights to the payouts and apply any terms and conditions as it may deem fit.<br />
<br />
Regards,<br />
Team Fairplay`,
    emailHtmlContentWinplay: `Greetings,<br />
<br />
We noticed you enquired about our Affiliate program. Here are some of the basic details regarding the program.<br />
<br />
Payout ratio to affiliates will be in accordance with number of players introduced and playing on our portal.<br />
<br />
1 to 50 Members, 10% payout on cummulative loss of players<br />
<br />
51 to 200 Members, 20% payout on cummulative loss of players<br />
<br />
200 + Members, 30% payout on cummulative loss of players.<br />
<br />
<br />
Payouts will be processed by the 5th of the following month. For more details, please contact us on directly via Whatsapp or you can alternatively reply back to this mail.<br />
<br />
Superwin reserves rights to the payouts and apply any terms and conditions as it may deem fit.<br />
<br />
Regards,<br />
Team Superwin`
})

export const mutations = {
    set_data(state, value) {
        state.data = value
    }
}

export const actions = {
    async getAll({ commit }) {
        try {
            const res = await service.apiCallWithHeader(
                "/ReferralTransaction/getTotalReferralAmount"
            )
            if (res && res.status === 200) {
                commit("set_data", res.data)
            }
            return res
        } catch (err) {}
    },
    async sendAffiliateEmail({ state }, data) {
        try {
            data.emailHtmlContent =
                process.env.APP_TITLE && process.env.APP_TITLE === "FairPlay"
                    ? state.emailHtmlContent
                    : state.emailHtmlContentWinplay
            const res = await service.apiCallWithHeader(
                "/User/sendAffiliateEmail",
                data
            )
            if (res && res.status === 200 && res.data === "Email Sent") {
                return "success"
            } else {
                return "error"
            }
        } catch (err) {
            return "error"
        }
    }
}
