<template>
    <div>
        <v-dialog v-model="cacheDialog" persistent max-width="400">
            <v-card light>
                <v-card-title>
                    <div class="title">New version available!</div>
                </v-card-title>
                <v-card-text class="pb-0"
                    >Please update to the new version to experience latest
                    features.</v-card-text
                >
                <v-card-actions class="px-6 py-4 text-left">
                    <v-btn
                        class=""
                        color="primary"
                        :loading="loading"
                        @click="clearCacheInstance()"
                        >Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex"
import constants from "../../../constant"
export default {
    // components: {
    //   constant
    // },
    data() {
        return {
            cacheDialog: false,
            loading: false,
            getVersion: ""
        }
    },
    computed: {
        ...mapState({ error: (state) => state.profile.error }),
        getsetversion() {
            return this.$store.state.profile.appversion
        }
    },
    watch: {
        getsetversion() {
            if (this.getsetversion) {
                this.checkVersionForWeb()
            }
        }
    },
    methods: {
        checkVersionForWeb() {
            this.getVersion = this.getsetversion.uVersion
            const oldVersion = constants.appVersion
            console.log(this.getVersion, oldVersion)
            if (this.getVersion && this.getVersion !== "9999.9999.9999.9999") {
                if (oldVersion === "" || oldVersion == null) {
                    this.saveVersion(this.getVersion)
                    this.cacheDialog = true
                } else if (oldVersion && oldVersion === this.getVersion) {
                    this.cacheDialog = false
                } else {
                    this.saveVersion(this.getVersion)
                    this.cacheDialog = true
                }
            }
            // else if (this.getVersion === undefined && this.error) {
            //   this.$router.push('/region-block')
            // }
        },
        closeCacheModal() {
            this.cacheDialog = false
        },
        saveVersion(getVersion) {
            this.getVersion = getVersion
        },
        clearCacheInstance() {
            this.loading = true
            caches.keys().then(function (cacheNames) {
                return cacheNames.map(function (cache) {
                    return caches.delete(cache).then(function (found) {
                        console.log("Delete Cache:-", found)
                    })
                })
            })
            this.loading = false
            this.cacheDialog = false
            if (process.client) {
                window.location.reload()
                // window.location.replace(window.location.href)
            }
        }
    }
}
</script>

<style></style>
